import React from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import Logo from "../../assets/images/Logo.svg"

import "./RegisterLayout.scss"

const RegisterLayout = ({ children, isFooter, isHomeInfo }) => {
  const logoClass = classNames({
    "register-layout__logo": true,
    "register-layout__logo--no-display": isHomeInfo,
  })

  const RegisterFooter = () => {
    return (
      <div className="register-layout__footer-container">
        <div className="register-layout__footer">
          <div>
            <p>256 arden street coogee nsw 2034</p>
          </div>
          <div>
            <p>65a macpherson street waverley nsw 2024</p>
          </div>
          <div>
            <p>R1107/488 pacific hwy st leondards sq.</p>
          </div>
          <div>
            <p>110 avoca street randwick nsw 2031</p>
          </div>
        </div>
        <div className="email-link">
          <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
        </div>
      </div>
    )
  }

  RegisterLayout.propTypes = {
    children: PropTypes.any.isRequired,
    isFooter: PropTypes.bool.isRequired,
    isHomeInfo: PropTypes.bool,
  }

  return (
    <div className="register-layout">
      <div className={logoClass}>
        <img src={Logo}></img>
        <div className="register-layout__logo__text">
          <p>Self service laundromat</p>
        </div>
      </div>
      {children}
      {isFooter ? <RegisterFooter /> : ""}
    </div>
  )
}

export default RegisterLayout
