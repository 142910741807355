import React from "react"

import RegisterLayout from "../components/Layout/RegisterLayout"
import CreditCardComponent from "../components/LoginRegister/CreditCardComponent"
import "../components/LoginRegister/Authenticate.scss"

const creditCardPayment = () => {
  return (
    <RegisterLayout isFooter={false}>
      <CreditCardComponent />
    </RegisterLayout>
  )
}
export default creditCardPayment
