import React, { useContext, useState, Fragment } from "react"

import * as Sentry from "@sentry/react"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js"
import classNames from "classnames"
import PropTypes from "prop-types"

import { AuthenticationContext } from "../../context"
import { isFormEmpty } from "../../utils/methods"
import { toastMessage, TYPE_ERROR } from "../UI/Toast"

import "./stripeFormTest.scss"

const scope = new Sentry.Scope()
scope.setTag("section", "STRIPE ERROR")

const StripeForm = ({ stripe, elements }) => {
  const { subscribePlan, isLoadingRequest, setIsLoadingRequest } = useContext(
    AuthenticationContext
  )

  const initialValue = {
    cardCvc: true,
    cardExpiry: true,
    cardName: true,
    cardNumber: true,
  }

  const [isChecked, setIsChecked] = useState(false)
  const [value, setValues] = useState(initialValue)

  const handleElementInputChange = (e) => {
    const elementType = e.elementType
    const empty = e.empty
    setValues((prev) => ({
      ...prev,
      [elementType]: empty,
    }))
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    if (value && value.length > 0 && value.trim().length > 0) {
      setValues((prev) => ({
        ...prev,
        [name]: false,
      }))
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: true,
      }))
    }
  }

  const handleCheck = () => {
    setIsChecked((prev) => !prev)
  }

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }
    if (isFormEmpty(value)) {
      toastMessage("All fields must be filled", TYPE_ERROR)
    } else if (!isChecked) {
      toastMessage("Please agree to Washroom Terms of Service", TYPE_ERROR)
    } else {
      if (!isLoadingRequest) {
        setIsLoadingRequest(true)
        const cardNumberElement = elements.getElement(CardNumberElement)
        const res = await stripe.createToken(cardNumberElement)
        setIsLoadingRequest(false)
        if (res.error) {
          toastMessage(res.error.message, TYPE_ERROR)

          if (process.env.GATSBY_SENTRY_DSN) {
            const tempError = new Error()
            tempError.message = res.error.message
            tempError.name = "STRIPE ERROR"
            Sentry.captureException(tempError, scope)
          }
        } else if (res.token) {
          subscribePlan(res.token.id)
        }
      }
    }
  }

  const style = {
    placeholder: "Please Enter",
    style: {
      base: {
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          backgroundColor: "#efefec",
          color: "#112a55",
          letterSpacing: "2px",
        },
        backgroundColor: "#efefec",
        color: "#112a55",
        fontFamily: "sans-serif",
        fontSize: "12px",
        fontWeight: 300,
      },
      invalid: {
        backgroundColor: "#efefec",
        color: "#112a55",
      },
    },
  }

  const checkBoxClassName = classNames({
    "credit-card-component__terms__checkbox": true,
    "credit-card-component__terms__checkbox--checked": isChecked,
  })

  const buttonClassName = classNames({
    "credit-card-component__submit": true,
    "credit-card-component__submit--not-disabled": !isLoadingRequest,
  })

  return (
    <Fragment>
      <form className="stripe-form">
        <div className="FormGroup FormRow">
          <label htmlFor="cardNumber">Card Number</label>
          <div className="FormRowInput">
            <CardNumberElement
              options={style}
              onChange={handleElementInputChange}
            />
          </div>
        </div>
        <div className="FormGroup FormRow">
          <label htmlFor="cardName">Card Name</label>
          <input
            type="text"
            name="cardName"
            required
            placeholder="Please Enter"
            className="FormRowInput"
            onChange={handleInputChange}
            id="cardNaaame"
            pattern="[A-Za-z]"
          />
        </div>
        <div className="FormGroup FormRow">
          <label htmlFor="expDate">Exp. Date</label>
          <div className="FormRowInput">
            <CardExpiryElement
              options={style}
              onChange={handleElementInputChange}
            />
          </div>
        </div>
        <div className="FormGroup FormRow">
          <label htmlFor="CVC">CVC</label>
          <div className="FormRowInput">
            <CardCvcElement
              options={style}
              onChange={handleElementInputChange}
            />
          </div>
        </div>
      </form>
      <div className="credit-card-component__terms">
        <div className={checkBoxClassName} onClick={handleCheck}></div>
        <div className="credit-card-component__terms__text">
          <p>
            I agree to Washroom{" "}
            <a href="/terms-of-service/" target="_blank">
              Terms of Service
            </a>
          </p>
        </div>
      </div>
      <div className={buttonClassName} onClick={handleSubmit}>
        Complete Registration
      </div>
    </Fragment>
  )
}

StripeForm.propTypes = {
  elements: PropTypes.any,
  stripe: PropTypes.any,
}

const CardForm = () => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <StripeForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  )
}

export default CardForm
