import React, { useContext, useEffect } from "react"

import { navigate } from "gatsby"

import { Elements } from "@stripe/react-stripe-js"

import { AuthenticationContext } from "../../context"
import { loadStripePromise } from "../../utils/methods"
import CardForm from "../Stripe/stripeForm"
import "./CreditCardComponent.scss"

const CreditCardComponent = () => {
  const { isUserSet, isAuthenticated, user, logOut } = useContext(
    AuthenticationContext
  )

  useEffect(() => {
    if (user.plan !== null && (!isAuthenticated || !isUserSet)) {
      logOut()
      navigate("/login")
    }
  }, [])

  const stripePromise = loadStripePromise()

  return (
    <div className="credit-card-container">
      <div className="credit-card-component">
        <div>
          <div className="credit-card-component__title">
            <p>Credit Card Payment</p>
          </div>
          <div className="credit-card-component__description">
            <p>
              We require payment details in order to process your booking;
              please fill in all relevant information below. Your card details
              will be linked to your account for future use. Thank you
            </p>
          </div>
          <div className="credit-card-component__form">
            <Elements stripe={stripePromise}>
              <CardForm />
            </Elements>
          </div>
        </div>
      </div>
      <div className="email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
    </div>
  )
}

export default CreditCardComponent
